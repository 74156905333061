import axios from "axios";
import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './App.css'
import {BrowserRouter as Router, Routes, Route}  from 'react-router-dom';
import Home from './components/pages/Home';
import Contact from './components/pages/Contact';
import ContactSuccess from './components/pages/ContactSuccess';
import About from './components/pages/About';
import Login from './components/pages/Login';
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import ServiceAgreement from './components/pages/ServiceAgreement';
  

const baseURL = "https://jsonplaceholder.typicode.com/posts/1";
function App() {
  const responseMessage = (response) => {
    console.log(response);
};
const errorMessage = (error) => {
    console.log(error);
};


  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    axios.get(baseURL).then((response) => {
      setPost(response.data);
    });
  }, []);

  if (!post) return null;
  return (
    <>
      <Router>
      <HelmetProvider>
      <Helmet>
      <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
      </Helmet>
        <Navbar />
        <Routes>
            <Route exact path='/' element={< Home />}></Route>
            <Route exact path='/about' element={< About />}></Route>
            <Route exact path='/contact' element={< Contact />}></Route>
            <Route exact path='/contactsuccess' element={< ContactSuccess />}></Route>
            <Route exact path='/login' element={< Login />}></Route>
            <Route exact path='/privacypolicy' element={< PrivacyPolicy />}></Route>
            <Route exact path='/serviceagreement' element={< ServiceAgreement />}></Route>
        </Routes>
        <Footer />
        </HelmetProvider>
      </Router>
    </>
  );
}

export default App;
