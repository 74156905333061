import React from 'react';
import '../../App.css';
import { Link } from 'react-router-dom'



export default function ContactSuccess() {

  return (
    <div>
      <h1>Thank you for reaching out</h1>
      <p align="center">Someone will get back to you as soon as possible.  In the meantime, checkout out our reviews from Airbnb</p> 
      </div>  )


}
