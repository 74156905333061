import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PrivacyPolicy';
import './ServiceAgreement';
import '../../App.css';

import { GoogleLogin } from '@react-oauth/google';

const Login = () => {
    const navigate = useNavigate();
    const responseMessage = (response) => {
        console.log(response);
        navigate('/dashboard'); // redirect to dashboard
    };
    const errorMessage = (error) => {
        console.log(error);
    };

  return (
    <div>
      <p> By Logging in your agree to our <a href="/privacypolicy">Privacy Policy</a> and <a href="/serviceagreement"> Service Agreement</a></p>
      <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
    </div>
  )
}

export default Login;