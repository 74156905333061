import React from 'react';

const ServiceAgreement = () => {
  return (
    <div>
      <h1>HostelChic Service Agreement</h1>
      <p>
  
This Service Agreement ("Agreement") is entered into between you ("User" or "you") and HostelChic ("we," "us," or "our") governing your use of the HostelChic website (the "Service"). By accessing or using our Service, you agree to be bound by this Agreement. If you do not agree to these terms, please do not use our Service.

1. Service Description

HostelChic is an online platform that allows users to book accommodations in hostels. Our Service includes but is not limited to:

Providing information about available hostels, their facilities, and amenities.
Allowing users to search for, view, and book hostel accommodations.
Processing payments for bookings made through our platform.
2. User Responsibilities

By using our Service, you agree to:

Provide accurate and complete information when making bookings.
Comply with all applicable laws and regulations regarding your use of our Service.
Use the Service responsibly and refrain from engaging in any unlawful or prohibited activities.
3. Booking and Payments

All bookings made through HostelChic are subject to availability.
Users are responsible for providing accurate payment information and ensuring timely payment for bookings.
Cancellation policies and fees may apply. Please refer to our cancellation policy for more details.
4. Intellectual Property

All content and materials on the HostelChic website, including but not limited to text, images, logos, and software, are the property of HostelChic and protected by intellectual property laws.
Users may not reproduce, distribute, or modify any content from the Service without prior written consent from HostelChic.
5. Limitation of Liability

HostelChic is not liable for any damages, losses, or expenses arising from your use of our Service, including but not limited to booking errors, cancellations, or disputes with hostel operators.
We do not guarantee the accuracy or availability of information provided on our website.
6. Privacy

Your privacy is important to us. Please refer to our Privacy Policy for information on how we collect, use, and protect your personal information.
7. Termination

We reserve the right to terminate or suspend your access to the Service at any time for any reason without prior notice.
8. Changes to the Agreement

HostelChic reserves the right to update or modify this Agreement at any time. Continued use of the Service after such changes constitutes your acceptance of the revised Agreement.
      </p>
    </div>
  );
}

export default ServiceAgreement;