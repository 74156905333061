import React from 'react';
import CardItem from './CardItem';
import './Cards.css';


function Cards() {
  return (
    <div className='cards' id='cards'>
    <h1>Check out Some of our Listings!</h1>
    <div className='cards__container'>
      <div className='cards__wrapper'> 
       <ul className='cards__items'>
        <CardItem
         src="images/4905_0.png"
         text="Private Room with a Private Bath"
         label='Private Suite'
         path="https://www.airbnb.com/rooms/54182937?source_impression_id=p3_1706357638_qFjHWJEDO8JzOgZ0"

         />
     
         <CardItem
         src="images/3519_4.png"
         text="Private Room Near Bus Stop"
         label='Private Room'
         path="https://www.airbnb.com/rooms/54069994?source_impression_id=p3_1657731513_Ps%2F45QgNIYKjuJvK"
         />
        
         <CardItem
         src="images/160.png"
         text="Close to the Entertainment & Sports Arena"
         label='Private Room'
         path="https://www.airbnb.com/rooms/54182136?source_impression_id=p3_1657731689_mz1aioEvQ7Z8rF%2Ft"
         />
       </ul>
       <div id="airbnb" className="elfsight-app-03473803-3ea3-43d5-b181-86476fc001a2" data-elfsight-app-lazy></div>
     </div>
   </div>
 </div>
 );
}

export default Cards;
