import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import { Link } from 'react-router-dom';


function HeroSection() {
  return (
    <div className='hero-container'>
    <h1>YOUR HOME AWAY FROM HOME AWAITS!</h1>
    <p>Enjoy great locations in the DC Area</p>
    
    <div className='hero-btns'>
      <a target="_blank" href="https://www.airbnb.com/users/90603359/listings?s=24">
        <Button
          type="button"
          className='btns'
          buttonSytle='btn--outline'
          buttonSize='btn--large'>
          View Listings
        </Button>
      </a>
      <a target="_blank" href="https://www.airbnb.com/users/90603359/listings?s=24">
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          >
          Learn More
        </Button>
       </a>
       <Link to="/BookDirectly">
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          >
          Book Directly
        </Button>
        </Link>
      
  </div>
  </div>
);

}
export default HeroSection;
