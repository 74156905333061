import React from 'react';
import { Link } from "react-router-dom";
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import { Helmet } from 'react-helmet-async';
import CardItem from '../CardItem';
const responsive = {
  desktop: {
    breakpoint: { max:3000, min:1024 },
    items : 3,
    slidesToSlide : 1 // optional, default to 1.
  }
  
};
function Home() {

  console.log( {breakpoint: { max:3000, min:1024 },items : 3
   // slidesToSlide : 3 // optional, default to 1.
  });   
  return (
    <>
      <Helmet>
      <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
      </Helmet>
      <HeroSection />
      <Cards />
    </>
  );
}

export default Home;
