import React from 'react';
import '../../App.css';




export default function About() {

  return (
    
  <h1>About Us</h1>

  )
}
