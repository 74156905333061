

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { QueryClient, QueryClientProvider } from "react-query";

import { GoogleOAuthProvider } from '@react-oauth/google';


<GoogleOAuthProvider clientId="1076491938291-s5bb3j60drvf5u2h20l7epsou62b6pta.apps.googleusercontent.com">...</GoogleOAuthProvider>;

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
    <GoogleOAuthProvider clientId="1076491938291-s5bb3j60drvf5u2h20l7epsou62b6pta.apps.googleusercontent.com">
    <App />
    </GoogleOAuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

