import React from 'react';
import './Footer.css';


function Footer(){
  return(
    <div className="footer-container">
    <div class="elfsight-app-34f000e3-2fd5-40a9-8cd4-6a7a1e4d73ce" data-elfsight-app-lazy></div>
    <small className='website-rights'>HostelChic © 2024 </small>
    </div>
  )
}
export default Footer;
